<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <settings-nav :user="user" :path="path"></settings-nav>
      </div>
      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <h4 class="fw-bold">Sender Addresses</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  components: {

  },
};
</script>

<style>
</style>
